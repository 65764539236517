<template>
      <div class="w-full rounded-10 h-auto bg-white">
        <div class="flex pl-4 p-6 pl-10">
          <div class="flex w-1/2">
            <steep :option="allSteep" :value="position" @info="backSteep"/>
          </div>

          <div class="w-1/2 flex justify-end">
            <div class="w-2/5 text-left">
             <multiselect :option="periode" value-t="Période" iconHeight="20" :icon="icons.calendar"/>
            </div>
          </div>
        </div>


        <div class="" >
          <devis :donnes="flow.allDevis" v-if="position === allSteep[0] "/>
          <facture :donnes="flow.allFacture" v-if="position === allSteep[1] "/>
          <recu :donnes="flow.billOfSales" v-if="position === allSteep[2] "/>
        </div>
      </div>
</template>

<script>
import multiselect from '../../helper/form/multiselect'
import calendar from '../../../assets/icons/calendar.svg'
import steep from './steepSelect'
import devis from './details/devis'
import facture from './details/facture'
import recu from './details/recu'

export default {
  name: "Index",
  components: {
    multiselect,
    steep,
    devis,
    facture,
    recu
  },

  props: {
    flow: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        calendar
      },
      periode: ['Année en cours'],
      allSteep: ['Devis', 'Factures', 'Reçus de vente'],
      position: 'Devis'
    }
  },

  methods: {
    backSteep (a) {
      this.position = a
    }
  }
}
</script>

<style scoped>
.diviser1{
  width: 2.68px;
  height: 33.91px;
  background: #79E38D;
}

.diviser2{
  width: 2.68px;
  height: 33.91px;
  background: #7BD0FF;
}
</style>
