<template>
  <div class="pl-6 pr-4">
    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.ref }}
      </div>

      <div class="w-1/6 ml-4">
        {{ item.createdate.substr(0, 10) }}
      </div>

      <div class="w-2/5 ml-4 flex items-center">
        <icon
            :data="icons.client"
            height="20"
            width="20"
            class="mr-2 cursor-pointer"
            original
        />
       <div> {{ item.name }} </div>
      </div>

      <div class="w-1/5 ml-4 font-c7">
       {{ parseInt(item.total).toLocaleString() }} fcfa
      </div>

      <div class="w-1/5 ml-4 flex">
        <icon
            :data="icons.espece"
            height="20"
            width="20"
            class="mr-2 cursor-pointer"
            original
        />
        <div class="text-c14 text-83 font-c5">{{ item.moypay }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import client from '../../../../assets/icons/ic-clientAv.svg'
import espece from '../../../../assets/icons/ic-espece.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        client,
        espece
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
