<template>

  <div class="p-6 pr-6 all">
    <popupCharge :activation="charge" v-if="charge"/>
    <div>
      <div
        class="flex items-center"
      >
        <icon
            :data="icons.back"
            height="40"
            width="40"
            class="mr-4 cursor-pointer"
            original
            @click="goBack"
        />

        <icon
            :data="icons.entreprise"
            height="20"
            width="20"
            class="mr-3 cursor-pointer"
            original
        />

        <div class="text-left text-c22 items-center font-c8">
         <span v-if="allDetail">{{ allDetail.commerce.fullName }}</span>
        </div>
      </div>

      <div class="mt-6 flex" v-if="allDetail">

        <div class="w-2/3">
          <globalStat :flow="allDetail"/>

          <div class="mt-6">
            <stat :flow="allDetail"/>
          </div>

          <div class="mt-6">
            <document :flow="allDetail"/>
          </div>

        </div>

        <div class="w-1/3 pl-6">
          <product :flow="allDetail.allProduct"/>

          <company :flow="allDetail" class="mt-6"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../assets/icons/ic-entreprise.svg'
import back from '../../assets/icons/backBut.svg'
import globalStat from '../../component/cdevs/commerceDetail/globalStat'
import product from '../../component/cdevs/commerceDetail/allProduct'
import company from '../../component/cdevs/commerceDetail/companyData'
import stat from '../../component/cdevs/commerceDetail/turnover'
import document from '../../component/cdevs/commerceDetail/document'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    globalStat,
    popupCharge,
    product,
    stat,
    document,
    company
  },

  data () {
    return {
      icons: {
        entreprise,
        back
      },
      charge: true,
      allDetail: null
    }
  },

  created () {
    this.getAllDetail()
  },

  methods: {

    retourSearch (answer) {
      this.recherche = answer
    },

    goBack () {
      window.history.back()
    },


    getAllDetail () {
      const company = this.$store.getters.trackCompany
      http.post(apiroutes.baseURL + apiroutes.commerceDetail, {
        id: company.id
      })
          .then(response => {
            this.allDetail = response
            // console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
  background-color: #F3F5F8;
}
</style>
