<template>
  <div class="w-full text-left bg-white bord">
    <div class="text-c16 font-c7 p-6">Produits</div>
    <div class="w-full h-0.5 bg-e5"/>

    <div class="p-6">
      <div class="text-center text-c12 text-90" v-if="flow.length === 0">Aucun produit enregistré</div>
      <div class="w-fulll bg-white flex items-center mb-4" v-for="(item, index) in flow" :key="index">
        <img src="../../../assets/images/jpg/lunette.jpg" alt="" class="mr-3 rounded-4">
        <div>
          <div class="mt-1 font-c8 text-c14"> {{ item.name }} </div>
          <div class="text-c12 text-83">Qté : {{ item.quantity }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import client from '../../../assets/icons/ic-client.svg'
import supplier from '../../../assets/icons/ic-supplier.svg'
import collaborater from '../../../assets/icons/ic-collaborater.svg'

export default {
  name: "Index",
props: {
  flow: {
    type: Array,
    default: null
  }
},
  data () {
    return {
      icons: {
        client,
        supplier,
        collaborater
      },
    }
  }
}
</script>

<style lang="scss" scoped>
img{
  object-fit: cover;
  width: 55px;
  height: 46px;
}
.bord {
  border: 1.02605px solid #EDEDEE;
  border-radius: 4px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
